<template>
    <admin-default v-slot:admin>
        <div class="application">
            <div class="container container-resize">
                <div class="row border-bottom pb-5">
                    <div class="col-md-9"><h1 class="application__secondary--title">Personal Details</h1></div>
                    <div class="col-6 col-md-3">
                        <h1 class="application__main--title">Welcome,</h1>
                        <h1 class="application__tertiary--title">{{ user.name }}</h1>
                    </div>
                </div>
            </div>

            <!--NAV-->
            <div class="navigation">
                <div class="container container-resize--1">
                    <nav class="application-nav">
                        <ul class="application-nav__lists">
                            <li class="application-nav__list">
                                <a href="#" class="application-nav__item">Personal Details</a>
                            </li>
                            <li class="application-nav__list">
                                <a href="#" class="application-nav__item">Employment Details</a>
                            </li>
                            <li class="application-nav__list">
                                <a href="#" class="application-nav__item">Loan Details</a>
                            </li>
                            <li class="application-nav__list">
                                <a href="#" class="application-nav__item">Disbursement Details</a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>

            <div class="container container-resize">
                <div class="row mt-5">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="corporate" class="form__label">Corporate/Group</label>
                            <select class="form-control form__input form__input--small" id="corporate">
                                <option>Individual</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                                <option>5</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="date" class="form__label">Date</label>
                            <input type="date" class="form-control form__input margin-bottom" id="date">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label for="dob" class="form__label">Date of Birth</label>
                            <input type="date" class="form-control form__input margin-bottom" id="dob">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="Birth" class="form__label">Birth Country</label>
                            <select class="form-control form__input form__input--small" id="Birth">
                                <option>Nigeria</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                                <option>5</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="City" class="form__label">Birth State/City</label>
                            <select class="form-control form__input margin-bottom" id="City">
                                <option>Lagos</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                                <option>5</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="Status" class="form__label">Marital Status</label>
                            <select class="form-control form__input form__input--small" id="Status">
                                <option>Married</option>
                                <option>Single</option>
                                <option>Divorce</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="Gender" class="form__label">Gender</label>
                            <select class="form-control form__input margin-bottom" id="Gender">
                                <option>Male</option>
                                <option>Female</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="Code" class="form__label">Country Code</label>
                            <select class="form-control form__input form__input--small" id="Code">
                                <option>(+234)</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                                <option>5</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="Phone" class="form__label">Phone Number</label>
                            <input type="tel" class="form-control form__input margin-bottom" id="Phone">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label for="Means" class="form__label">Means of Identification</label>
                            <select class="form-control form__input margin-bottom" id="Means">
                                <option>International Passport</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                                <option>5</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="form-group"><label class="form__label form__label-big">ID Card No.</label></div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="Issue" class="form__label form__label-small">Issue Date</label>
                            <input type="date" class="form-control form__input form__input--small" id="Issue">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="Expiry" class="form__label form__label-small">Expiry Date</label>
                            <input type="date" class="form-control form__input margin-bottom" id="Expiry">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label for="Issuance" class="form__label">Country of Issuance</label>
                            <select class="form-control form__input margin-bottom" id="Issuance">
                                <option>Nigeria</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                                <option>5</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="Origin" class="form__label">State of Origin</label>
                            <select class="form-control form__input form__input--small" id="Origin">
                                <option>Delta</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                                <option>5</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="local" class="form__label">L.G.A</label>
                            <select class="form-control form__input margin-bottom" id="local">
                                <option>Isoko North</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                                <option>5</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label for="Country" class="form__label">Country</label>
                            <select class="form-control form__input margin-bottom" id="Country">
                                <option>Nigeria</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                                <option>5</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label for="Email" class="form__label">Email Address</label>
                            <input type="email" class="form-control form__input margin-bottom" id="Email" placeholder="example@gmail.com">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label for="Residential" class="form__label">Residential Address</label>
                            <textarea class="form-control form__input margin-bottom" id="Residential" rows="8"></textarea>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label for="Loan" class="form__label">Particulars of Loan</label>
                            <select class="form-control form__input margin-bottom" id="Loan">
                                <option>Rent</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                                <option>5</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="sub__title">
                    <h1 class="heading__primary">Next of Kin Information</h1>
                </div>

                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label for="Surname" class="form__label">Surname</label>
                            <input type="text" class="form-control form__input margin-bottom" id="Surname" placeholder="Idemudia Precious">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="First" class="form__label">First Name</label>
                            <input type="text" class="form-control form__input form__input--small" placeholder="Individual" id="First">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="Middle" class="form__label">Middle Name</label>
                            <input type="text" class="form-control form__input margin-bottom" id="Middle">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="Relationship" class="form__label">Relationship</label>
                            <select class="form-control form__input form__input--small" id="Relationship">
                                <option>Brother</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                                <option>5</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="Other" class="form__label">Other</label>
                            <input type="text" class="form-control form__input margin-bottom" id="Other" placeholder="(Please specify)">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="code" class="form__label">Country Code</label>
                            <select class="form-control form__input form__input--small" id="code">
                                <option>(+234)</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                                <option>5</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="Tel" class="form__label">Telephone No.</label>
                            <input type="tel" class="form-control form__input margin-bottom" id="Tel">
                        </div>
                    </div>
                </div>

                <div class="button--box">
                    <button type="button" class="btn button button-cta cta">Save</button>
                    <button type="button" class="btn button button-cta cta">Continue</button>
                    <button type="button" class="btn button btn-outline-primary button-outline outline-1">Back</button>
                </div>
            </div>
        </div>
    </admin-default>
</template>

<script>
    import AdminDefault from "../navigate/AdminDefault";
    export default {
        name: "Personal",
        components: {AdminDefault}
    }
</script>

<style scoped>

</style>